import React from 'react'
import { API } from '@src/constants/api'
import {
  importBandsV2BenchmarkTypeColumn,
  importBandsV2CurrencyISOCodeColumn,
  importBandsV2LocationColumn,
  importBandsV2LowerBandColumn,
  importBandsV2OnTargetBonusColumn,
  importBandsV2SeniorityColumn,
  importBandsV2SeniorityLevelColumn,
  importBandsV2SpecialisationColumn,
  importBandsV2UpperBandColumn,
} from '@src/constants/columns/importBandsV2'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { RowInterface } from '@src/interfaces/data'
import { ImportBandsV2Interface } from '@src/interfaces/importBandsV2'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<ImportBandsV2Interface>> => ({
  cells: [
    {
      ...importBandsV2SpecialisationColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsV2SeniorityColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsV2SeniorityLevelColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsV2LocationColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsV2BenchmarkTypeColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsV2CurrencyISOCodeColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsV2LowerBandColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsV2UpperBandColumn(options.onChange),
      width: 200,
    },
    {
      ...importBandsV2OnTargetBonusColumn(options.onChange),
      width: 200,
    },
  ],
})

export const ImportBandsV2 = () => {
  return (
    <BulkDataUploadV2
      apiEndpoint={API.BENCHMARKS_BULK_UPLOAD}
      apiVersion="v2"
      anyRoute={ROUTES.FORMS.IMPORT_DATA.COMPENSATION_BANDS.ANY}
      importRoute={ROUTES.FORMS.IMPORT_DATA.COMPENSATION_BANDS.UPLOAD_FILE}
      sessionRoute={ROUTES.FORMS.IMPORT_DATA.COMPENSATION_BANDS.REVIEW_DATA}
      category="benchmarks_bulk_upload"
      entity="band"
      name="band"
      row={row}
      tableName={TableNames.UploadBands}
      header={<PageHeader backUrl={ROUTES.MAIN} title="Import bands" />}
    />
  )
}
