import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { Route } from 'react-router-dom'
import { EmployeeInterface } from '@src/interfaces/employees'
import { MeetingsTable } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsTable/MeetingsTable'

interface Props {
  data: EmployeeInterface
}

export const LayoutTab = ({ data }: Props) => {
  return (
    <>
      <Route exact path={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.OVERVIEW}>
        <MeetingsTable employee={data} />
      </Route>
    </>
  )
}
