import React, { useState } from 'react'
import { PageBody } from '@components/Page/PageBody'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import { Item } from '@revolut/ui-kit'
import { Logo2Fa, LogoGoogle, Text } from '@revolut/icons'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { handleError } from '@src/api'
import axios from 'axios'
import { SecurityErrorPopup } from '@src/pages/Settings/Security/SecurityErrorPopup'
import { useDispatch, useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useQueryClient } from 'react-query'
import { API, Environments, env } from '@src/constants/api'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { SecuritySettingsInterface } from '@src/interfaces/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { logOutAction } from '@src/store/auth/actions'

export const Security = () => {
  const { initialValues } = useLapeContext<SecuritySettingsInterface>()

  const [error, setError] = useState<string>()
  const [twoFactorTurnedOn, setTwoFactorTurnedOn] = useState(false)

  const permissions = useSelector(selectPermissions)
  const dispatch = useDispatch()

  const queryClient = useQueryClient()
  const canEdit = permissions.includes(PermissionTypes.ChangeSecuritySettings)

  const onError = (e: Error) => {
    if (!axios.isAxiosError(e)) {
      throw e
    }
    if (e?.response?.data?.non_field_error?.[0]) {
      setError(e?.response?.data?.non_field_error?.[0])
      return
    }
    handleError(e)
  }

  return (
    <>
      <PageBody>
        <AutoStepper>
          {/* TODO: https://revolut.atlassian.net/browse/REVCOR-3210 for prod release should be visible in Environments.productionCommercial as well */}
          {env === Environments.productionCommercial ? null : (
            <>
              <NewStepperTitle
                title="Log in with SSO"
                subtitle="Choose which tool do you allow your employees to login"
              />
              <Item use="label" useIcon={LogoGoogle}>
                <Item.Content>
                  <Item.Title>Sign in with Google account</Item.Title>
                </Item.Content>
                <Item.Side>
                  <LapeNewSwitch name="enable_google_sso" disabled={!canEdit} />
                </Item.Side>
              </Item>
            </>
          )}
          <NewStepperTitle
            title="2-factor authentication"
            subtitle="Your employees will be asked to create a 2-Step Login via SMS messages."
          />
          <Item use="label" useIcon={Logo2Fa}>
            <Item.Content>
              <Item.Title>Activate 2-factor authentication</Item.Title>
            </Item.Content>
            <Item.Side>
              <LapeNewSwitch
                name="enable_two_factor_authentication"
                disabled={!canEdit}
                onAfterChange={enableTwoFA => {
                  setTwoFactorTurnedOn(
                    !initialValues.enable_two_factor_authentication && enableTwoFA,
                  )
                }}
              />
            </Item.Side>
          </Item>
          <HideIfCommercial>
            <NewStepperTitle
              title="Allow users to log in with Password"
              subtitle="If you activate this option, users can log in with their email address and password "
            />
            <Item use="label" useIcon={Text}>
              <Item.Content>
                <Item.Title>Allow password authentication</Item.Title>
              </Item.Content>
              <Item.Side>
                <LapeNewSwitch
                  name="enable_password_authentication"
                  disabled={!canEdit}
                />
              </Item.Side>
            </Item>
          </HideIfCommercial>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup<SecuritySettingsInterface>
          successText="Settings saved successfully"
          onSubmitError={onError}
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.LOGIN_SETTINGS)
            if (twoFactorTurnedOn) {
              dispatch(logOutAction())
            }
          }}
          confirmationDialogue={
            twoFactorTurnedOn
              ? {
                  label: 'Enabling 2-Factor authentication',
                  body: 'Enabling 2 factor authentication for the first time will require you to re-login. Do you want to proceed?',
                  yesMessage: 'Confirm',
                  noMessage: 'Cancel',
                  variant: 'compact',
                }
              : undefined
          }
        />
      </PageActions>

      <SecurityErrorPopup error={error} onClose={() => setError(undefined)} />
    </>
  )
}
