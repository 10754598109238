import React, { useState } from 'react'
import { FilterButton } from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import Table from '@src/components/TableV2/Table'
import { ROUTES } from '@src/constants/routes'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { companyMeetingsRequests, OneToOneMeetingsQueryKeys } from '@src/api/meetings'
import { CompanyMeetingsProgress } from './MeetingsProgress'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { TableNames } from '@src/constants/table'
import { OneToOneMeeting, OneToOneMeetingsStats } from '@src/interfaces/meetings'
import {
  companyMeetingOwnerColumn,
  companyMeetingRelationColumn,
  companyEmployeeColumn,
  companyMeetingDepartmentnColumn,
} from '../constants/columns'
import { recurrenceColumn, timeColumn, statusColumn } from '../constants/columns'

const initialSortBy = [
  {
    sortBy: OneToOneMeetingsQueryKeys.ManagerFullName,
    direction: SORT_DIRECTION.DESC,
  },
]

const ROW: RowInterface<OneToOneMeeting> = {
  cells: [
    {
      ...companyMeetingOwnerColumn,
      width: 200,
    },
    {
      ...companyMeetingRelationColumn,
      width: 200,
    },
    {
      ...companyMeetingDepartmentnColumn,
      width: 200,
    },
    {
      ...companyEmployeeColumn,
      width: 200,
    },

    {
      ...timeColumn,
      width: 200,
    },

    {
      ...recurrenceColumn,
      width: 200,
    },

    { ...statusColumn, width: 200 },
  ],
}

export const CompanyMeetings = () => {
  const [showMissingOnly, setShowMissingOnly] = useState(false)

  const table = useTable(companyMeetingsRequests, undefined, initialSortBy)

  const onSwitchMissingStatus = () => {
    table.onFilterChange([
      {
        columnName: OneToOneMeetingsQueryKeys.NoMeeting,
        filters: showMissingOnly ? [] : [{ id: 'True', name: 'True' }],
      },
    ])

    setShowMissingOnly(!showMissingOnly)
  }
  return (
    <PageWrapper>
      <PageHeader title="1:1 Meetings" backUrl={ROUTES.PERFORMANCE.REVIEWS.GENERAL} />
      <Table.Widget>
        <Table.Widget.Info>
          <CompanyMeetingsProgress isLoading={table.loading} stats={table.stats} />
        </Table.Widget.Info>
        <Table.Widget.Filters>
          <Table.Search
            placeholder="Search by employee name"
            onFilter={table.onFilterChange}
            variant="compact"
          />
          <FilterButton active={showMissingOnly} onClick={onSwitchMissingStatus}>
            Missing only
          </FilterButton>
        </Table.Widget.Filters>
        {/* TODO: Will be implemented in next task https://revolut.atlassian.net/browse/REVC-7333 */}
        {/* <Table.Widget.Actions>
          <Table.Widget.MoreBar maxCount={2}>
            <MoreBar.Action useIcon="LinkExternal">Browser Extension</MoreBar.Action>
            <MoreBar.Action useIcon="Form">Manage Templates</MoreBar.Action>
            <Table.ColumnsSettingsButton />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions> */}
        <Table.Widget.Table>
          <AdjustableTable<OneToOneMeeting, OneToOneMeetingsStats>
            name={TableNames.CompanyMeetings}
            useWindowScroll
            row={ROW}
            {...table}
            emptyState={<EmptyTableRaw title="There are no meetings" />}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </PageWrapper>
  )
}
