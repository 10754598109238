import {
  GetRequestInterface,
  RequestInterface,
  TableRequestInterface,
} from '@src/interfaces'
import { api } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  OneToOneMeeting,
  MeetingCompanionInterface,
  MeetingInterface,
  MeetingWithCompanionDetail,
  MeetingWithManagersStats,
  OneToOneMeetingsStats,
} from '@src/interfaces/meetings'
import { useFetch } from '@src/utils/reactQuery'
import { getCommentsAPI } from '@src/api/comments'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'

export const getMeetingCompanionsRequests = (
  employeeId: number,
): RequestInterface<MeetingCompanionInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id =>
    api.get(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings/${id}`),
  patchItem: async (data, id) =>
    api.patch(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings/${id}`, data),
  deleteItem: async id =>
    api.delete(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings/${id}`),
  postItem: async data =>
    api.post(`${API.ONE_TO_ONES}/${employeeId}/allRelationsWithMeetings`, data),
})

export const getMeetingsWithCompanionRequests = (
  employeeId: number | string, // employee at whose page the data is requested
  companionId: number | string, // employee with whom the meetings are
): RequestInterface<MeetingInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    ),
  getItem: async id =>
    api.get(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee/${id}`,
    ),
  patchItem: async (data, id) =>
    api.patch(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee/${id}`,
      data,
    ),
  deleteItem: async id =>
    api.delete(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee/${id}`,
    ),
  postItem: async data =>
    api.post(
      `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithRelationEmployee`,
      data,
    ),
})

export const useGetManagerMeetingsStats = (employeeId: number) => {
  return useFetch<MeetingWithManagersStats>(
    `${API.ONE_TO_ONES}/${employeeId}/meetingsWithManagers/stats`,
  )
}

export const useGetCompanionMeetingsDetails = (
  employeeId: number | string,
  companionId: number | string,
) => {
  return useFetch<MeetingWithCompanionDetail>(
    `${API.ONE_TO_ONES}/${employeeId}/${companionId}/meetingsWithDetailEmployee/detail`,
  )
}

export const submitCalendarTracking = (syncNow = false) => {
  return api.post(
    `${API.ONE_TO_ONES}/newMeetingsForEmployee/fetch`,
    syncNow ? { sync_now: true } : {},
  )
}

export const companyMeetingsRequests: TableRequestInterface<
  OneToOneMeeting,
  OneToOneMeetingsStats
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.MEETINGS}/managerMeetings`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v1',
    ),
  getStats: async ({ filters }) =>
    api.get(
      `${API.MEETINGS}/managerMeetings/stats`,
      {
        params: filterSortPageIntoQuery(undefined, filters),
      },
      'v1',
    ),
}

export const getOneToOneCommentsApi = (meetingId: number) =>
  getCommentsAPI({ baseUrl: `${API.ONE_TO_ONES}/${meetingId}/comments` })

export const getEmployeeMeetings = (
  id: number,
  { sortBy, filters, page }: FetchDataQueryInterface,
): AxiosPromise<GetRequestInterface<OneToOneMeeting>> =>
  api.get(`${API.MEETINGS}/employeeManagerMeetings/${id}`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export enum OneToOneMeetingsQueryKeys {
  EmployeeId = 'employee_id',
  ManagerId = 'manager_id',
  EmployeeFullName = 'employee__full_name',
  ManagerFullName = 'manager__full_name',
  EmployeeRelationType = 'employee_relation_type',
  RelationType = 'relation_type',
  ManagerTeamDepartmentId = 'manager__team__department_id',
  ManagerTeamDepartmentName = 'manager__team__department__name',
  NoMeeting = 'is_missing_meeting',
}

export enum OneToOneMeetingsFiltersQueryKeys {
  ParticipantId = 'participant_id',
  ParticipantFullName = 'participant__full_name',
  // searching by following fields: manager__full_name, employee__full_name
  Search = 'search',
}
