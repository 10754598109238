import React, { useMemo } from 'react'
import Table from '@src/components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { useTable } from '@components/TableV2/hooks'
import { getEmployeeMeetings, OneToOneMeetingsQueryKeys } from '@src/api/meetings'
import { TableNames } from '@src/constants/table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { getRow } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsTable/Row'
import { EmployeeInterface } from '@src/interfaces/employees'
import { SORT_DIRECTION } from '@src/interfaces/data'

const initialSortBy = [
  {
    sortBy: OneToOneMeetingsQueryKeys.EmployeeRelationType,
    direction: SORT_DIRECTION.ASC,
  },
]

interface Props {
  employee: EmployeeInterface
}

export const MeetingsTable = ({ employee }: Props) => {
  const table = useTable(
    { getItems: params => getEmployeeMeetings(employee.id, params) },
    [],
    initialSortBy,
  )
  const row = useMemo(() => getRow(employee), [employee.id])

  return (
    <Table.Widget>
      <Table.Widget.Filters>
        <Table.Search
          placeholder="Search by participant name"
          onFilter={table.onFilterChange}
          variant="compact"
        />
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<OneToOneMeeting>
          name={TableNames.EmployeeMeetings}
          row={row}
          hideCount
          {...table}
          emptyState={<EmptyTableRaw title="1:1 meetings will appear here." />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
