import { Attendee, OneToOneMeeting } from '@src/interfaces/meetings'
import { EmployeeInterface } from '@src/interfaces/employees'

interface OneToOneModel {
  getDependantAttendee: (
    employee: EmployeeInterface,
    meeting: OneToOneMeeting,
  ) => Attendee
  isScheduled: (meeting: OneToOneMeeting) => boolean
}

const getDependantAttendee = (
  employee: EmployeeInterface,
  meeting: OneToOneMeeting,
): Attendee => {
  if (employee.id === meeting.employee.id) {
    return meeting.manager
  }

  return meeting.employee
}

const isScheduled = (meeting: OneToOneMeeting): boolean => meeting.meeting_series !== null

const createOneToOneModel = (): OneToOneModel => {
  return {
    getDependantAttendee,
    isScheduled,
  }
}

export const oneToOneModel = createOneToOneModel()
