import { API } from '@src/constants/api'
import {
  GetRequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import {
  PayCycleInterface,
  PayCycleReportStatsInterface,
  PayCycleReportTableItemInterface,
  PayGroupInterface,
  PayGroupListItemInterface,
  PayrollTimelineChangeInterface,
  PayrollTimelineEventInterface,
  PayrunMonthSchedule,
  PayrunPreviewPayloadInterface,
} from '@src/interfaces/payrollV2'
import { useFetch, usePost } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { api, apiWithoutHandling } from '.'
import { getCommentsAPI } from './comments'

export const payGroupTableRequests = {
  getItems: ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<PayGroupListItemInterface>>(
      `${API.PAYROLL}/payGroups`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
}

// TODO: RHR-5776 migrate to v2 API when BE is ready
export const useGetPayCycle = (id: string | undefined) =>
  useFetch<PayCycleInterface>({
    url: `${API.PAYROLL}/cycles/${id}`,
    version: 'v1',
    queryOptions: { enabled: !!id },
  })

export const getPayCycleEmployeeReportsTableRequests = (
  id: string | number | undefined,
): TableRequestInterface<
  PayCycleReportTableItemInterface,
  PayCycleReportStatsInterface
> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.PAYROLL}/payCycles/${id}/employeeReports`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
  getStats: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.PAYROLL}/payCycles/${id}/employeeReports/stats`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const useGetPayCycles = (params: object, enabled: boolean = true) =>
  useFetch<GetRequestInterface<PayCycleInterface>>({
    url: `${API.PAYROLL}/cycles`,
    version: 'v1',
    params: { params },
    queryOptions: { enabled },
  })

export const getPayGroupTimelineTableRequests = (
  id: number,
): TableRequestInterface<PayrollTimelineEventInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.PAYROLL}/payGroups/${id}/timeline`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const useGetPayGroupTimeline = (
  id: string | number | undefined,
  from: string,
  to: string,
) =>
  useFetch<GetRequestInterface<PayrollTimelineEventInterface>>({
    url: `${API.PAYROLL}/payGroups/${id}/timeline`,
    version: 'v2',
    params: {
      params: {
        from_date: from,
        to_date: to,
      },
    },
    queryOptions: { enabled: !!id },
  })

export const useGetPayCycleReportChanges = (
  cycleId: number | undefined,
  reportId: number | undefined,
) =>
  useFetch<PayrollTimelineChangeInterface[]>({
    url: `${API.PAYROLL}/employeeReports/${reportId}/changes`,
    version: 'v2',
    queryOptions: { enabled: Boolean(cycleId && reportId) },
  })

export const useGetPayRollChangeDetails = (changeId: number | undefined) =>
  useFetch<PayrollTimelineChangeInterface>({
    url: `${API.PAYROLL}/changes/${changeId}`,
    version: 'v2',
    queryOptions: { enabled: !!changeId },
  })

export const getPayCycleCommentsAPI = (payCycleId: string | number) =>
  getCommentsAPI({
    baseUrl: `${API.PAYROLL}/cycles/${payCycleId}/comments`,
  })

export const payGroupRequests: RequestInterfaceNew<PayGroupInterface> = {
  get: async ({ id }) => api.get(`${API.PAY_GROUPS}/${id}`),
  submit: async data => apiWithoutHandling.post(API.PAY_GROUPS, data),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.PAY_GROUPS}/${id}`, data),
  delete: async ({ id }) => api.delete(`${API.PAY_GROUPS}/${id}`),
}

export const validatePayGroup = (data: PayGroupInterface) =>
  apiWithoutHandling.post(`${API.PAY_GROUPS}/validate`, data)

export const useUpdatePayrunSchedule = (handleError?: (err: Error) => void) =>
  usePost<
    PayrunPreviewPayloadInterface,
    Array<PayrunMonthSchedule>,
    PayrunPreviewPayloadInterface
  >({
    url: `${API.PAY_GROUPS}/payScheduleDates`,
    handleError,
    withoutHandling: true,
  })
