import { format } from 'date-fns'
import pluralize from 'pluralize'
import { formatRRule } from '@src/utils/format'
import { meetingRecurrence } from '@src/interfaces/meetingsTracker'
import { OneToOneMeeting } from '@src/interfaces/meetings'

const missingTimeDiffLabel = '-'

export const formatRecurrentTimeDiff = (meeting: OneToOneMeeting): string => {
  const { meeting_series: meetingSeries } = meeting

  if (meetingSeries === null) {
    return missingTimeDiffLabel
  }

  const startDate = new Date(meetingSeries.start)
  const endDate = new Date(meetingSeries.end)

  const recurrence = formatRRule(meetingSeries.recurrence)
  const day = format(startDate, 'eeee')
  const dayFormatted =
    recurrence === meetingRecurrence.Daily || recurrence === meetingRecurrence.Custom
      ? day
      : pluralize(day)
  const fromTime = format(startDate, 'HH:mm')
  const toTime = format(endDate, 'HH:mm')

  return `${dayFormatted}, ${fromTime} - ${toTime}`
}
